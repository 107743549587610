import { useState } from "react";
import { Button, Container, Image, Row } from "react-bootstrap";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

let initialState = [
  "10_of_clubs.png",
  "10_of_diamonds.png",
  "10_of_hearts.png",
  "10_of_spades.png",
  "2_of_clubs.png",
  "2_of_diamonds.png",
  "2_of_hearts.png",
  "2_of_spades.png",
  "3_of_clubs.png",
  "3_of_diamonds.png",
  "3_of_hearts.png",
  "3_of_spades.png",
  "4_of_clubs.png",
  "4_of_diamonds.png",
  "4_of_hearts.png",
  "4_of_spades.png",
  "5_of_clubs.png",
  "5_of_diamonds.png",
  "5_of_hearts.png",
  "5_of_spades.png",
  "6_of_clubs.png",
  "6_of_diamonds.png",
  "6_of_hearts.png",
  "6_of_spades.png",
  "7_of_clubs.png",
  "7_of_diamonds.png",
  "7_of_hearts.png",
  "7_of_spades.png",
  "8_of_clubs.png",
  "8_of_diamonds.png",
  "8_of_hearts.png",
  "8_of_spades.png",
  "9_of_clubs.png",
  "9_of_diamonds.png",
  "9_of_hearts.png",
  "9_of_spades.png",
  "ace_of_clubs.png",
  "ace_of_diamonds.png",
  "ace_of_hearts.png",
  "ace_of_spades.png",
  "jack_of_clubs.png",
  "jack_of_diamonds.png",
  "jack_of_hearts.png",
  "jack_of_spades.png",
  "king_of_clubs.png",
  "king_of_diamonds.png",
  "king_of_hearts.png",
  "king_of_spades.png",
  "queen_of_clubs.png",
  "queen_of_diamonds.png",
  "queen_of_hearts.png",
  "queen_of_spades.png",
];

function App() {
  let [imageSet, setImage] = useState(initialState);
  let [drawnImages, setDrawnImages] = useState([]);
  let [currentImage, setCurrentImage] = useState([]);

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(require.context("./cards", false, /\.(png)$/));


  useState(() => {
    newCard()
  }, [])

  function newCard() {
    // console.log("card set len: ", imageSet.length)
    let cardNum = Math.floor(Math.random() * imageSet.length) - 1;
    console.log("card num: ", cardNum)
    let image = imageSet[cardNum];
    if (images[image] === undefined) {
      console.log("card num: ", cardNum);
      console.log("image: ", image);
      console.log("undefined card");
      return;
    }
    // console.log("image: ", image)
    setCurrentImage(images[image]);
    let testimage = imageSet.splice(cardNum, 1);
    let copyOfDrawnImages = [...drawnImages];
    copyOfDrawnImages.push(testimage[0]);
    setDrawnImages(copyOfDrawnImages);
  }

  function clearCards() {
    setImage(initialState);
    setDrawnImages([]);
    setCurrentImage([]);
  }

  return (
    <div className="App">
      <h1>Pokeno Dealer  -  <Link to="/card">Player</Link>  -  <a href="https://bicyclecards.com/wp-content/uploads/2018/02/Pokeno-PnP-How-to-Play.pdf" target="_blank">Rules</a></h1>
      <Container id="drawn-image-row">
        <Row>
          <img src={currentImage} id="current-image" />
        </Row>
        <Container id="card-buttons">
          <Button id="clear-card-button" onClick={clearCards}>
            Clear Cards
          </Button>
          <Button id="draw-card-button" onClick={newCard}>
          Draw
          </Button>
        </Container>
      </Container>
      <h3>Drawn Cards</h3>
      <Container id="drawn-card-container">
        {drawnImages.map((imageSrc) => <img src={images[imageSrc]} id="drawn-images" />)}
      </Container>
    </div>
  );
}

export default App;
