import { Routes, Route } from 'react-router-dom';
import App from './App';
import Card from './Card';


export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<App />}></Route>
            <Route path="/card" element={<Card />}></Route>    
        </Routes>
    )
}