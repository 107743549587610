import { useState, useEffect, useContext} from "react";
import { Button, Card, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./App.css";
import CardContext from "./CardContext";


let initialState = [
  "10_of_clubs.png",
  "10_of_diamonds.png",
  "10_of_hearts.png",
  "10_of_spades.png",
  "2_of_clubs.png",
  "2_of_diamonds.png",
  "2_of_hearts.png",
  "2_of_spades.png",
  "3_of_clubs.png",
  "3_of_diamonds.png",
  "3_of_hearts.png",
  "3_of_spades.png",
  "4_of_clubs.png",
  "4_of_diamonds.png",
  "4_of_hearts.png",
  "4_of_spades.png",
  "5_of_clubs.png",
  "5_of_diamonds.png",
  "5_of_hearts.png",
  "5_of_spades.png",
  "6_of_clubs.png",
  "6_of_diamonds.png",
  "6_of_hearts.png",
  "6_of_spades.png",
  "7_of_clubs.png",
  "7_of_diamonds.png",
  "7_of_hearts.png",
  "7_of_spades.png",
  "8_of_clubs.png",
  "8_of_diamonds.png",
  "8_of_hearts.png",
  "8_of_spades.png",
  "9_of_clubs.png",
  "9_of_diamonds.png",
  "9_of_hearts.png",
  "9_of_spades.png",
  "ace_of_clubs.png",
  "ace_of_diamonds.png",
  "ace_of_hearts.png",
  "ace_of_spades.png",
  "jack_of_clubs.png",
  "jack_of_diamonds.png",
  "jack_of_hearts.png",
  "jack_of_spades.png",
  "king_of_clubs.png",
  "king_of_diamonds.png",
  "king_of_hearts.png",
  "king_of_spades.png",
  "queen_of_clubs.png",
  "queen_of_diamonds.png",
  "queen_of_hearts.png",
  "queen_of_spades.png",
];

function RowCard({card, index, rowNum}) {
    let [imageSet, setImage] = useState(initialState);
    const [state, dispatch] = useContext(CardContext)


    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => {
            images[item.replace("./", "")] = r(item);
        });
        return images;
        }

    const images = importAll(require.context("./cards", false, /\.(png)$/));
 
    function changeClass() {
        // console.log(card.number)
        // console.log(rowNum)
        // console.log(index)
        
        let playerCardsCopy = [...state.playersCards];
        // console.log("state: ", playerCardsCopy[rowNum][index])

        let cardStatus = playerCardsCopy[rowNum][index].selected;
        // console.log("CardStatus: ", cardStatus);

        playerCardsCopy[rowNum][index].selected = !cardStatus;
        // console.log("stateCopy after update: ", playerCardsCopy)

       dispatch({type: "updateCards" , payload: {...state, playersCards:playerCardsCopy}})
    }

  return (
    <>
        <Image onClick={changeClass} className={card.selected ? "selected-card" : "unselected-card"} 
             id="card-images" 
             src={images[imageSet[card.number]]} />
    </>
      
  );
}

export default RowCard;