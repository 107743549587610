import { useState, useEffect, useReducer } from "react";
import { Button, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./App.css";
import CardRow from "./CardRow";
import CardContext from "./CardContext";

let initialState = [
  "10_of_clubs.png",
  "10_of_diamonds.png",
  "10_of_hearts.png",
  "10_of_spades.png",
  "2_of_clubs.png",
  "2_of_diamonds.png",
  "2_of_hearts.png",
  "2_of_spades.png",
  "3_of_clubs.png",
  "3_of_diamonds.png",
  "3_of_hearts.png",
  "3_of_spades.png",
  "4_of_clubs.png",
  "4_of_diamonds.png",
  "4_of_hearts.png",
  "4_of_spades.png",
  "5_of_clubs.png",
  "5_of_diamonds.png",
  "5_of_hearts.png",
  "5_of_spades.png",
  "6_of_clubs.png",
  "6_of_diamonds.png",
  "6_of_hearts.png",
  "6_of_spades.png",
  "7_of_clubs.png",
  "7_of_diamonds.png",
  "7_of_hearts.png",
  "7_of_spades.png",
  "8_of_clubs.png",
  "8_of_diamonds.png",
  "8_of_hearts.png",
  "8_of_spades.png",
  "9_of_clubs.png",
  "9_of_diamonds.png",
  "9_of_hearts.png",
  "9_of_spades.png",
  "ace_of_clubs.png",
  "ace_of_diamonds.png",
  "ace_of_hearts.png",
  "ace_of_spades.png",
  "jack_of_clubs.png",
  "jack_of_diamonds.png",
  "jack_of_hearts.png",
  "jack_of_spades.png",
  "king_of_clubs.png",
  "king_of_diamonds.png",
  "king_of_hearts.png",
  "king_of_spades.png",
  "queen_of_clubs.png",
  "queen_of_diamonds.png",
  "queen_of_hearts.png",
  "queen_of_spades.png",
];

let iState = {
  playersCards: [],
  pokenoCheck: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "updateCards":
      return action.payload;
    case "pokenoWinner":
      return { ...state, pokenoCheck: action.payload };
  }
}

function Card() {
  const [state, dispatch] = useReducer(reducer, iState);

  // let [imageSet, setImage] = useState(initialState);
  let [gridOfNumbers, setGridOfNumbers] = useState([]);
  // let [arrayOfNumbers, setArrayOfNumbers] = useState([]);

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(require.context("./cards", false, /\.(png)$/));

  useEffect(() => {
    createNumberArray();
  }, []);

  useEffect(() => {
    checkForPokeno();
  }, [state.playersCards]);

  function createNumberArray() {
    let gridArray = [];
    while (gridArray.length < 25) {
      let randNum = Math.floor(Math.random() * 52);
      // console.log("randNum: ", randNum)
      let numIndex = gridArray.indexOf(randNum);
      // console.log("numIndex: ", numIndex)
      if (numIndex === -1) {
        gridArray.push(randNum);
      }
    }
    // setArrayOfNumbers(gridArray)
    createObjectArray(gridArray);
    // console.log("grid: ", gridArray);
  }

  function createObjectArray(arr) {
    let newObject = [];
    arr.forEach((num) => {
      let numObject = {
        number: num,
        selected: false,
      };
      newObject.push(numObject);
    });
    // setArrayOfNumbers(newObject)
    createGrid(newObject);
  }

  function createGrid(arr) {
    let grid = [];
    let row1 = arr.slice(0, 5);
    let row2 = arr.slice(5, 10);
    let row3 = arr.slice(10, 15);
    let row4 = arr.slice(15, 20);
    let row5 = arr.slice(20);
    // console.log("row1: ", row1);
    grid.push(row1);
    grid.push(row2);
    grid.push(row3);
    grid.push(row4);
    grid.push(row5);
    setGridOfNumbers(grid);
    let newState = { ...state, playersCards: grid };
    dispatch({ type: "updateCards", payload: newState });
    // console.log(grid);
  }

  function checkForPokeno() {
    // check for all in the same row
    let startingValue = state.pokenoCheck;
    let currentValue = false;
    if (state.playersCards === undefined) {
      return;
    }
    // if (!state.pokenoCheck) {
    state.playersCards.forEach((row) => {
      if ((currentValue = false)) {
        let pc = true;
        for (let i = 0; i < 5; i++) {
          // if(!pc){
          //   return;
          // }
          if (!row[i].selected) {
            pc = false;
            return;
          }
        }
        dispatch({ type: "pokenoWinner", payload: pc });
      }
    });
    // }
    // if()
    // check for all up and down
    // check for diaganls
    // dispatch({ type: "pokenoWinner", payload: false });
  }

  function clearCard() {
    let freshCard = [...state.playersCards];
    for (let row = 0; row < 5; row++) {
      for (let card = 0; card < 5; card++) {
        console.log("row: ", row);
        console.log("card: ", card);
        freshCard[row][card].selected = false;
      }
    }
    let newState = { ...state, playersCards: freshCard };
    dispatch({ type: "updateCards", payload: newState });
  }

  return (
    <CardContext.Provider value={[state, dispatch]}>
      <div className="App">
        <h1>
          Pokeno <Link to="/">Dealer</Link> - Player  -  <a href="https://bicyclecards.com/wp-content/uploads/2018/02/Pokeno-PnP-How-to-Play.pdf" target="_blank">Rules</a>
        </h1>
        {/* <h2>{state.pokenoCheck ? "Winner!" : "My Card"}</h2> */}
        <h2>My Card</h2>
        {gridOfNumbers.map((row, i) => {
          // {gridOfNumbers.map((row, i) => {
          return <CardRow rowArray={row} rowIndex={i} />;
        })}
      </div>
      <Container id="card-buttons">
        <Button id="clear-card" onClick={clearCard}>
          Clear Card
        </Button>
        <Button id="clear-card" onClick={createNumberArray}>
          New Card
        </Button>
      </Container>
    </CardContext.Provider>
  );
}

export default Card;
